import React from "react"
import { Link } from "gatsby"

import { Row, Col, Divider } from "antd"
import "../styles/footer.css"

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_upper_section">
        <Row align="middle" justify="space-around">
          <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
            <Row justify="space-around">
              <Link to="/" className="footer_heading">
                Product
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Usecases
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Pricing
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Roadmap
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                FAQ
              </Link>
            </Row>
          </Col>

          <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
            <Row justify="space-around">
              <Link to="/about" className="footer_heading">
                Company
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/about#mission" className="footer_sub_heading">
                Mission
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/about#team" className="footer_sub_heading">
                Team
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/about#vision" className="footer_sub_heading">
                Vision
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/about#contact" className="footer_sub_heading">
                Contact
              </Link>
            </Row>
          </Col>

          <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
            <Row justify="space-around">
              <Link to="/" className="footer_heading">
                Community
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Blog
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                News
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Press Kit
              </Link>
            </Row>
            <Row justify="space-around">
              <Link to="/" className="footer_sub_heading">
                Forum
              </Link>
            </Row>
          </Col>
        </Row>
      </div>
      <Row justify="space-around">
        <Divider style={{ borderColor: "grey" }}></Divider>
        <Col className="footer_copyright_section">
          <Link to="/" className="footer_sub_heading">
            Privacy Policy and Legal Agreements
          </Link>
          <div className="footer_copyright">
            Copyright 2018-{new Date().getFullYear()} © Fluxpower Pty Ltd ABN:
            48625419249
          </div>
          <div className="footer_copyright">Patents Pending Worldwide</div>
        </Col>
      </Row>
    </div>
  )
}

export default Footer
