import React from "react"
import { Menu, Row, Col, Button } from "antd"
import { Link } from "gatsby"
import "../styles/header.css"

var mixpanel = require("mixpanel-browser")

const Header = () => (
  <Row justify="center" align="middle" className="header">
    {/* this Col controls the proportion of whitespace between the other Cols */}
    <Col
      xxl={{ span: 16, offset: 0 }}
      xl={{ span: 14, offset: 0 }}
      lg={{ span: 10, offset: 0 }}
      md={{ span: 8, offset: 0 }}
      sm={{ span: 7, offset: 0 }}
      xs={{ span: 0, offset: 0 }}
    >
      <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
        <Link to="/">
          <img
            src="https://fluxpower-website-images.s3-ap-southeast-2.amazonaws.com/logo.png"
            alt="logo"
            className="header_logo_image"
          />{" "}
          <text className="header_logo_title">FLUXPOWER</text>
        </Link>
      </Menu>
    </Col>
    <Col xxl={5} xl={7} lg={10} md={12} sm={13} xs={8}>
      <Row>
        <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
          <Menu.Item key="2">
            {" "}
            PRODUCT
            {/* <Link to="/product">PRODUCT</Link> */}
          </Menu.Item>
          <Menu.Item key="1">
            <Link to="/about">COMPANY</Link>
          </Menu.Item>
          <Menu.Item key="3">BLOG</Menu.Item>
        </Menu>
      </Row>
    </Col>
    {/* for mobile */}
    <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={10}>
      <div>
        <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
          <Link to="/" className="header_logo_title_mobile">
            FLUXPOWER
          </Link>
        </Menu>
      </div>
    </Col>
    <Col xxl={3} xl={3} lg={4} md={4} sm={0} xs={0}>
      <div>
        <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
          <Button
            type="primary"
            size="large"
            target="_blank"
            rel="noopener noreferrer"
            href="https://web.fluxpower.io/login"
            onClick={() => {
              mixpanel.track("Login")
            }}
          >
            LOGIN
          </Button>
        </Menu>
      </div>
    </Col>
    <Col xxl={0} xl={0} lg={0} md={0} sm={4} xs={0}>
      <div>
        <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
          <Button
            type="primary"
            size="middle"
            target="_blank"
            rel="noopener noreferrer"
            href="https://web.fluxpower.io/login"
            onClick={() => {
              mixpanel.track("Login")
            }}
          >
            LOGIN
          </Button>
        </Menu>
      </div>
    </Col>
    <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={6}>
      <div>
        <Menu mode="horizontal" style={{ lineHeight: "72px" }}>
          <Button
            type="primary"
            size="small"
            target="_blank"
            rel="noopener noreferrer"
            href="https://web.fluxpower.io/login"
            onClick={() => {
              mixpanel.track("Login")
            }}
          >
            LOGIN
          </Button>
        </Menu>
      </div>
    </Col>
  </Row>
)

export default Header
