import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/global.css"

const SiteLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="layout_main_body">{children}</div>
      <Footer />
    </>
  )
}

export default SiteLayout
